<template>
  <div>
    <v-btn
      variant="outlined"
      rounded
      style="margin-left: 30px"
      class="mdc-button mdc-button--outlined"
      @click="signInWithGoogle"
    >
      <v-img
        style="width: 24px; height: 24px"
        alt=""
        :src="require('@/assets/google-logo.png')"
      ></v-img>
      <span
        style="
          padding-left: 16px;
          text-transform: none;
          font-size: 14px;
          font-family: 'Roboto', sans-serif;
          letter-spacing: normal;
        "
        >Sign in with Google</span
      >
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("auth", ["signInWithGoogle"]),
  },
};
</script>
