<template>
  <div ref="mySidenav" class="sidenav">
    <!-- SIDEBAR TITLE SECTION -->
    <v-list
      class="pt-5 pb-5"
      style="background-color: rgb(var(--v-theme-toolbar))"
    >
      <v-row no-gutters class="pa-0">
        <v-col style="max-width: 70px"> </v-col>
        <v-col>
          <v-row no-gutters>
            <div
              ref="myTitle"
              class="mt-1 ml-1 slideable slideout"
              style="font-size: 30px; padding-left: 12px"
            >
              <v-badge color="primary" offset-x="-10" content="beta"
                >wotMatters</v-badge
              >
            </div>

            <div class="removable">
              <v-btn
                icon
                class="close-button"
                size="x-small"
                @click="$emit('updateDrawerOpen', false)"
                style="
                  margin-top: 11px;
                  background: rgb(var(--v-theme-background2));
                "
              >
                <v-icon> mdi-chevron-left </v-icon></v-btn
              >
            </div>
          </v-row>
        </v-col>

        <!-- This is placed here, so the animated title (above) comes out under this -->
        <div
          style="
            position: absolute;
            left: 0;
            width: 65px;
            height: 65px;
            padding-left: 15px;
            padding-right: 15px;
            background: rgb(var(--v-theme-toolbar));
          "
        >
          <div style="text-align: center">
            <v-avatar class="my-2">
              <v-img :src="require('@/assets/wmLogo-96x96.png')"></v-img>
            </v-avatar>
          </div>
        </div>
      </v-row>
    </v-list>

    <v-divider></v-divider>

    <!-- SIDEBAR BUTTONS -->
    <v-row no-gutter class="ma-0">
      <v-col
        no-gutter
        class="pa-0 pb-5 d-flex align-start flex-column"
        style="max-width: 80px; height: calc(100vh - 95px)"
      >
        <div
          @click="selectSidebarButton('SEARCH')"
          class="sidebar-button"
          :class="
            selectedSidebarButton == 'SEARCH' ? 'sidebar-button-selected' : ''
          "
        >
          <v-icon color="white" x-large> mdi-magnify </v-icon>
          <div class="sidebar-label">Search</div>
        </div>

        <div
          @click="selectSidebarButton('JOURNAL')"
          class="sidebar-button"
          :class="
            selectedSidebarButton == 'JOURNAL' ? 'sidebar-button-selected' : ''
          "
        >
          <v-icon color="white" large> mdi-notebook </v-icon>
          <div class="sidebar-label">Jotter</div>
        </div>

        <div
          @click="selectSidebarButton('CALENDAR')"
          class="sidebar-button mb-auto"
          :class="
            selectedSidebarButton == 'CALENDAR' ? 'sidebar-button-selected' : ''
          "
        >
          <v-badge
            v-if="eventBadgeContent != '' && eventBadgeContent != 'NONE'"
            :color="
              eventStarted ? 'error' : eventSoon ? 'warning' : 'primary'
            "
            :content="eventBadgeContent"
            overlap
          >
            <v-icon color="white" large> mdi-calendar-blank </v-icon>
          </v-badge>
          <v-icon v-else color="white" large> mdi-calendar-blank </v-icon>
          <div class="sidebar-label">Events</div>
        </div>

        <div
          @click="selectSidebarButton('ACCOUNT')"
          class="sidebar-button"
          :class="
            selectedSidebarButton == 'ACCOUNT' ? 'sidebar-button-selected' : ''
          "
        >
          <v-icon color="white" large> mdi-account-circle </v-icon>
          <div class="sidebar-label">Account</div>
        </div>

        <div
          @click="selectSidebarButton('SETTINGS')"
          class="sidebar-button"
          :class="
            selectedSidebarButton == 'SETTINGS' ? 'sidebar-button-selected' : ''
          "
        >
          <v-icon color="white" large> mdi-cog </v-icon>
          <div class="sidebar-label">Settings</div>
        </div>
      </v-col>

      <!-- SIDEBAR CONTENT -->
      <v-col
        no-gutter
        class="pa-0 pt-2 fadeable"
        style="
          overflow: hidden;
          background-color: rgb(var(--v-theme-background2));
        "
      >
        <div
          class="sidebar-content px-4"
          v-show="selectedSidebarButton == 'SEARCH'"
        >
          <wmNavDrawerFileList
            parentTaskId="journal"
            autoLoad="false"
            fileListSearchQ="trashed=false"
            showAddNote="false"
            addNoteLabelType=""
            addNoteLabelId=""
            autoCreateNote=""
            manualRefresh=""
          />
        </div>

        <div
          class="sidebar-content px-4"
          v-show="selectedSidebarButton == 'JOURNAL'"
        >
          <wmNavDrawerFileList
            parentTaskId="journal"
            autoLoad="true"
            fileListSearchQ="trashed=false and appProperties has { key='journal' and value='task' }"
            showAddNote="true"
            addNoteLabelType="task"
            addNoteLabelId="journal"
            :autoCreateNote="autoCreateJournalNote"
            manualRefresh=""
            :openDriveDocsParam="openDriveFiles"
            @setAutoCreateNote="setAutoCreateJournalNote"
            @openDriveFilesChanged="openDriveFilesChanged"
          />
        </div>

        <div
          class="sidebar-content"
          v-show="selectedSidebarButton == 'CALENDAR'"
        >
          <wmNavDrawerCalendar
            @changeBadge="changeBadge"
            @updateDrawerOpen="$emit('updateDrawerOpen')"
            @updateDrawerSelected="selectSidebarButton"
            @updateAutoCreateNote="updateautoCreateJournalNote"
          />
        </div>

        <div
          class="sidebar-content"
          v-show="selectedSidebarButton == 'ACCOUNT'"
        >
          <div class="text-center">
            <v-avatar size="x-large" class="mt-12">
              <v-img
                :src="getCurrentUser && getCurrentUser.photoURL"
                alt="My Photo"
              ></v-img>
            </v-avatar>

            <h3 style="margin: auto; color: white" class="mt-6">
              {{ getCurrentUser && getCurrentUser.displayName }}
            </h3>
            <p style="margin: auto; color: white" class="mt-6">
              {{ getCurrentUser && getCurrentUser.email }}
            </p>
            <v-btn
              variant="outlined"
              color="secondary"
              class="mt-4"
              @click="logout"
            >
              Logout
            </v-btn>
          </div>
        </div>

        <div
          class="sidebar-content"
          v-show="selectedSidebarButton == 'SETTINGS'"
        >
          <div v-if="getDriveAppFolder" class="text-center">
            <v-icon color="white" size="x-large" class="mt-12">
              mdi-cog
            </v-icon>
            <p style="margin: auto; color: white" class="mt-6">My Settings</p>
            <v-text-field
              class="mt-10 px-4 primary"
              style="margin: auto; color: white"
              label="Application GDrive Folder"
              v-model="getDriveAppFolderName"
              readonly
              base-color="white"
              color="white"
              variant="outlined"
              prepend-inner-icon="mdi-folder"
            ></v-text-field>
          </div>

          <div v-else class="text-center">
            <v-icon color="white" size="x-large" class="mt-12">
              mdi-cog
            </v-icon>
            <p style="margin: auto; color: white" class="mt-6">
              No Settings available
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import wmNavDrawerFileList from "./wmNavDrawerFileList.vue";
import wmNavDrawerCalendar from "./wmNavDrawerCalendar.vue";

export default {
  components: {
    wmNavDrawerFileList,
    wmNavDrawerCalendar,
  },
  emits: ["updateDrawerOpen"],
  data() {
    return {
      selectedSidebarButton: "",
      eventBadgeContent: "",
      // focussedJournalNoteId: "",
      autoCreateJournalNote: "",
      openDriveFiles: [],
    };
  },
  watch: {
    getDrawerOpen(newValue) {
      if (newValue) {
        this.openNav();
      } else {
        this.closeNav();
      }
    },
  },
  computed: {
    ...mapGetters("task", ["getAllTasks", "getFirstTaskGroup"]),
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("settings", ["getDriveAppFolder", "getDriveAppFolderName"]),
    ...mapGetters("ui", ["getDrawerOpen"]),
    // focussedJournalNoteIndex() {
    //   // Needs to be an array to support Multiple option in the expansion panel list of notes
    //   var retVal = [];
    //   retVal.push(
    //     this.getJournalNotes &&
    //       this.getJournalNotes.length > 0 &&
    //       this.focussedJournalNoteId
    //       ? this.getJournalNotes.findIndex(
    //           (element) => element.id == this.focussedJournalNoteId
    //         )
    //       : -1
    //   );
    //   return retVal;
    // },
    // Events for display on the VueCal component

    eventStarted() {
      return this.eventBadgeContent == "Now";
    },
    eventSoon() {
      return (
        this.eventBadgeContent &&
        this.eventBadgeContent.includes("m") &&
        Number(
          this.eventBadgeContent.substring(
            0,
            this.eventBadgeContent.indexOf("m")
          )
        ) <= 5
      );
    },
  },
  methods: {
    ...mapActions("auth", ["signOutFromGoogle"]),
    openNav() {
      this.$refs.mySidenav.classList.add("openNav");
      this.fadeFadeables(true);
      this.setRemovables(false);

      this.$refs.myTitle.classList.add("slidein");
      this.$refs.myTitle.classList.remove("slideout");
    },
    closeNav() {
      this.selectedSidebarButton = "";
      this.$refs.mySidenav.classList.remove("openNav");
      this.fadeFadeables(false);
      this.setRemovables(true);

      this.$refs.myTitle.classList.remove("slidein");
      this.$refs.myTitle.classList.add("slideout");
    },
    selectSidebarButton(buttonId) {
      if (buttonId == this.selectedSidebarButton) {
        this.$emit("updateDrawerOpen", false);
      } else {
        this.selectedSidebarButton = buttonId;
        this.$emit("updateDrawerOpen", true);
      }
    },
    fadeFadeables(isFadeIn) {
      const fadeables = document.getElementsByClassName("fadeable");
      for (let i = 0; i < fadeables.length; i++) {
        if (isFadeIn) {
          fadeables[i].classList.remove("fadeout");
          fadeables[i].classList.add("fadein");
        } else {
          fadeables[i].classList.add("fadeout");
          fadeables[i].classList.remove("fadein");
        }
      }
    },
    setRemovables(isRemoved) {
      const removables = document.getElementsByClassName("removable");
      for (let i = 0; i < removables.length; i++) {
        if (isRemoved) {
          removables[i].classList.remove("removedin");
          removables[i].classList.add("removedout");
        } else {
          removables[i].classList.add("removedin");
          removables[i].classList.remove("removedout");
        }
      }
    },
    changeBadge(newValue) {
      this.eventBadgeContent = newValue;
    },
    // createJournalNote() {
    //   this.addNote({
    //     taskId: "Journal",
    //     content: '{"type":"doc","content":[{"type":"paragraph"}]}',
    //   }).then((res) => this.setFocussedJournalNote(res.id));
    // },
    // formatDateforTime(inputDate) {
    //   return moment(inputDate).format("hh:mm");
    // },
    updateautoCreateJournalNote(newValue) {
      this.autoCreateJournalNote = newValue;
    },
    setAutoCreateJournalNote(newValue) {
      this.autoCreateJournalNote = newValue;
    },
    openDriveFilesChanged(openDriveFiles) {
      this.openDriveFiles = openDriveFiles;
    },
    logout() {
      this.$emit("updateDrawerOpen", false);
      this.signOutFromGoogle();
    },
  },
};
</script>

<style scoped>
/* The side navigation menu */
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 70px; /* 0 width - change this with JavaScript */
  min-width: 80px;
  /*position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: rgb(var(--v-theme-toolbar));
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.33s ease;
}
.close-button {
  right: 10px;
  transition: all 0.33s ease;
}
.openNav {
  width: 500px;
}
.sidebar-button {
  width: 80px;
  text-align: center;
  padding: 20px 0;
  transition: background-color 0.1s ease-in-out;
}
.sidebar-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.sidebar-button-selected {
  /* background-color: rgba(31, 64, 104, 0.5); */
  background-color: rgb(var(--v-theme-background2));
}
.sidebar-button-selected:hover {
  background-color: rgba(31, 64, 104, 1);
}
.sidebar-label {
  font-size: 16px;
}
.sidebar-content {
  width: 100%;
  /* padding: 0 8px 8px 8px; */
  text-decoration: none;
  font-size: 1rem;
  color: #818181;
  display: block;
}

.slideable {
  transition: opacity 0.33s ease;
  transition-delay: 0.5s;
  transition-property: opacity, left, visibility;
  position: relative;
}
.slideout {
  transition: opacity 0.33s top 0.33s ease;
  transition-delay: 0s;
  transition-property: opacity, left, visibility;
  position: relative;
  left: -175px;
  visibility: hidden;
}
.slidein {
  left: 0;
  visibility: visible;
}

.fadeable {
  visibility: hidden;
  opacity: 0;
}
.fadein {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.33s ease;
}
.fadeout {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.1s, opacity 0.1s linear;
}

.removable {
  position: absolute;
  top: 20;
  left: 0;
  transition-delay: 0.5s;
  transition: left 0.33s ease;
}
.removedin {
  left: 460px;
}
.removedout {
  left: 0;
}
</style>
