// v9 compat packages are API compatible with v8 code
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// Environment Settings
// PROD ENVIRONMENT
// NB This doesn't have the databaseURL - not sure if I need it
const firebaseConfig = {
  apiKey: "AIzaSyDocnsKhHup-EliCr4WxMFw0_SZukdQd7Q",
  authDomain: "app.wotmatters.com",
  projectId: "wotmatters-prod-5217c",
  storageBucket: "wotmatters-prod-5217c.appspot.com",
  messagingSenderId: "352998370848",
  appId: "1:352998370848:web:e537890527037dfaf99645",
  measurementId: "G-11GZZ2XTXG"
};

export var firebaseApp;
export var db;

// Initialize Firebase
export const initialiseFirebase = () => {
  firebaseApp = firebase.initializeApp(firebaseConfig);
  
  db = firebaseApp.firestore({
    experimentalForceLongPolling: true,
  });
};
