import { db } from "../../modules/firestore";

const state = {
  listeners: [],
  settings: [],
};

const GDRIVE_APP_FOLDER = "gdrive_app_folder";

const getters = {
  getDriveAppFolder(state) {
    const settingIndex = state.settings.findIndex(
      (setting) => setting.id === GDRIVE_APP_FOLDER
    );

    return settingIndex > -1 ? state.settings[settingIndex] : null;
  },
  getDriveAppFolderName(state) {
    const settingIndex = state.settings.findIndex(
      (setting) => setting.id === GDRIVE_APP_FOLDER
    );

    return settingIndex > -1 ? state.settings[settingIndex].folderName : "";
  },
  getDriveAppFolderId(state) {
    const settingIndex = state.settings.findIndex(
      (setting) => setting.id === GDRIVE_APP_FOLDER
    );

    return settingIndex > -1 ? state.settings[settingIndex].folderId : "";
  },
};

const mutations = {
  // LISTENERS
  setListener(state, payload) {
    state.listeners.push(payload);
  },
  removeAllListeners(state) {
    state.listeners = [];
  },

  // SETTINGS MUTATIONS
  addSetting(state, payload) {
    state.settings.push(payload);
  },
  setSetting(state, payload) {
    const settingIndex = state.settings.findIndex(
      (setting) => setting.id === payload.id
    );

    if (settingIndex > -1) {
      // Remove then add, otherwise Array change isn't triggered on client
      state.settings.splice(settingIndex, 1, payload);
    }
  },
  removeSetting(state, payload) {
    const settingIndex = state.settings.findIndex(
      (setting) => setting.id === payload.id
    );

    if (settingIndex > -1) {
      state.settings.splice(settingIndex, 1);
    }
  },
  clearSettings(state) {
    state.settings = [];
  },
};

const actions = {
  // ================
  // Listener methods
  // ================
  stopAllSettingsListeners({ commit, state }) {
    for (let listener of state.listeners) {
      listener.listener();
    }
    commit("removeAllListeners");
  },

  setSettingsListener({ commit, rootGetters }) {
    commit("clearSettings");

    const listener = db
      .collection(
        "users/" + rootGetters["auth/getCurrentUser"].uid + "/settings"
      )
      .onSnapshot((settingsRef) => {
        settingsRef.docChanges().forEach((change) => {
          if (change.type === "added") {
            commit("addSetting", { id: change.doc.id, ...change.doc.data() });
          }
          if (change.type === "modified") {
            commit("setSetting", { id: change.doc.id, ...change.doc.data() });
          }
          if (change.type === "removed") {
            commit("removeSetting", { id: change.doc.id });
          }
        });
      },
      error => {
        console.log("Snapshot error")
        console.log(error)
      });

    // store listener so it can be closed (eg. when logged out)
    commit("setListener", { type: "setting", listener: listener });
  },

  updateDriveAppFolder({ rootGetters }, { folderId, folderName }) {
    db.collection(
      "users/" + rootGetters["auth/getCurrentUser"].uid + "/settings"
    )
      .doc(GDRIVE_APP_FOLDER)
      .set({
        folderId: folderId,
        folderName: folderName,
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
