import Vuex from "vuex";

import auth from "@/store/auth";
import googleapi from "@/store/googleapi";
import settings from "@/store/settings";
import task from "@/store/task";
import ui from "@/store/ui";

const store = Vuex.createStore({
  state: {
    appName: "wotMatters",
    appVerison: "5.0"
  },
  modules: {
    auth,
    googleapi,
    settings,
    task,
    ui
  }
});

export default store
