function isCEInstalled() {
  return document.getElementById("wotMattersCE");
}

function openWindowsHelper(taskId, taskTitle, links) {
  if (isCEInstalled()) {
    try {
      window.postMessage({ type: "openLinks", taskId, taskTitle, links });
    } catch (error) {
      console.log("error");
      console.log(error);
    }
  }
}

function focusWindowHelper(linkId) {
  if (isCEInstalled()) {
    try {
      window.postMessage({ type: "focusLink", linkId });
    } catch (error) {
      console.log("error");
      console.log(error);
    }
  }
}

function closeWindowHelper(linkIds, exactMatch) {
  if (isCEInstalled()) {
    try {
      window.postMessage({ type: "closeLink", linkIds, exactMatch });
    } catch (error) {
      console.log("error");
      console.log(error);
    }
  }
}

function replaceLinkTabHelper(
  parentTaskId,
  parentTaskTitle,
  linkId,
  newLinkId,
  newLinkUrl
) {
  if (isCEInstalled()) {
    try {
      window.postMessage({
        type: "replaceLink",
        parentTaskId,
        parentTaskTitle,
        linkId,
        newLinkId,
        newLinkUrl
      });
    } catch (error) {
      console.log("error");
      console.log(error);
    }
  }
}

function clearLinksHelper() {
  if (isCEInstalled()) {
    try {
      window.postMessage({ type: "clearLinks" });
    } catch (error) {
      console.log("error");
      console.log(error);
    }
  }
}

export {
  isCEInstalled,
  openWindowsHelper,
  focusWindowHelper,
  closeWindowHelper,
  replaceLinkTabHelper,
  clearLinksHelper,
};
