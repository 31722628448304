<template>
  <v-menu v-model="showAddFileMenu" :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ props }">
      <v-btn
        color="primary"
        icon
        size="x-small"
        v-bind="props"
        style="margin-top: 5px"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-list v-show="!showCreateNoteForm && !showUploadForm">
      <v-list-item link @click="startCreateNote" title="Create Document">
        <template v-slot:prepend>
          <v-icon color="grey-lighten-1">mdi-text-box-plus</v-icon>
        </template>
      </v-list-item>

      <v-list-item link @click="startUploadFile" title="Upload File">
        <template v-slot:prepend>
          <v-icon color="grey-lighten-1">mdi-upload</v-icon>
        </template>
      </v-list-item>
    </v-list>

    <v-card v-show="showCreateNoteForm">
      <v-form v-model="isCreateNoteValid">
        <v-list>
          <v-list-item disabled title="Create Document">
            <template v-slot:prepend>
              <v-icon color="grey-lighten-1">mdi-text-box-plus</v-icon>
            </template>
          </v-list-item>

          <v-card-text class="py-0">
            <v-text-field
              dense
              auto-grow
              class="subtitle-1 font-weight-regular"
              v-model="newNoteName"
              background-color="transparent"
              style="width: 300px"
              label="Note Title"
              :rules="[
                () =>
                  (showAddFileMenu && !!newNoteName) ||
                  'This field is required',
              ]"
            >
            </v-text-field>

            <v-card-actions class="pr-0">
              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                text
                :disabled="!isCreateNoteValid"
                @click="createNote(newNoteName)"
              >
                Create
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-list>
      </v-form>
    </v-card>

    <v-card v-show="showUploadForm">
      <v-form v-model="isUploadValid">
        <v-list>
          <v-list-item disabled title="Upload File">
            <template v-slot:prepend>
              <v-icon color="grey lighten-1">mdi-upload</v-icon>
            </template>
          </v-list-item>

          <v-card-text class="py-0">
            <v-file-input
              density="compact"
              class="subtitle-1 font-weight-regular"
              v-model="uploadFileName"
              bg-color="transparent"
              style="width: 400px"
              hint="(Click field to select file)"
              :persistent-hint="true"
              :rules="[
                () =>
                  (showAddFileMenu && !!uploadFileName) ||
                  'This field is required',
              ]"
              @update:modelValue="fileSelected"
            >
            </v-file-input>

            <v-card-actions class="pr-0">
              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                text
                :disabled="!isUploadValid || uploadFileName.length === 0"
                @click="uploadFile"
              >
                Upload
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-list>
      </v-form>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  emits: ["requestCreateDriveDoc", "requestUploadDriveFile]"],
  props: {
    showAddNote: String,
  },
  data() {
    return {
      showAddFileMenu: false,
      showCreateNoteForm: false,
      isCreateNoteValid: false,
      newNoteName: "",

      showUploadForm: false,
      isUploadValid: false,
      uploadFileName: [],
      uploadFiles: [],
    };
  },
  watch: {
    showAddFileMenu: {
      handler(newValue) {
        // This watch is needed since I've set the menu to close-on-content-click="false"
        // This is relying on vue to set it to false when I click outside of the menu
        if (!newValue) {
          this.resetAddFileMenu();
        }
      },
    },
  },
  computed: {
    ...mapGetters("settings", ["getDriveAppFolder"]),
  },

  methods: {
    ...mapActions("googleapi", ["createDoc", "setFileProperty"]),
    startCreateNote() {
      this.showCreateNoteForm = true;
      this.newNoteName = "";
    },
    createNote(title) {
      this.$emit("requestCreateDriveDoc", title);

      // Reset menu flags
      this.resetAddFileMenu();
    },
    resetAddFileMenu() {
      this.showAddFileMenu = false;

      // HACK: This is a timer as it was showing the top level menu items before closing it, when clicking outside of showing target task
      var v = this;
      setTimeout(function () {
        v.showCreateNoteForm = false;
        v.showUploadForm = false;
      }, 600);
    },
    fileSelected(e) {
      this.uploadFiles = e;
    },
    startUploadFile() {
      this.showUploadForm = true;
      this.uploadFileName = [];
    },
    uploadFile() {
      this.uploadFiles.forEach((ele) => {
        this.$emit("requestUploadDriveFile", ele);
      });

      // Reset menu flags
      this.resetAddFileMenu();
    },
  },
};
</script>
