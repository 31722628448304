import { db, firebaseApp } from "../../modules/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import moment from "moment";

// Environment Settings
import { clientId } from "../env-settings";

const state = {
  listeners: [],
  tokens: [],
  calendarEvents: [],
};

const getters = {
  getGDriveAccessStatus(state) {
    const accessToken = state.tokens.find(
      (token) => token.id === "gdrive_access_token"
    );
    const accessStatus =
      accessToken && accessToken.token && accessToken.token === "pending"
        ? "pending"
        : accessToken && accessToken.token && accessToken.token.length > 0
        ? "granted"
        : "denied";

    const refreshToken = state.tokens.find(
      (token) => token.id === "gdrive_refresh_token"
    );
    const refreshStatus =
      refreshToken && refreshToken.token && refreshToken.token === "pending"
        ? "pending"
        : refreshToken && refreshToken.token && refreshToken.token.length > 0
        ? "granted"
        : "denied";

    return accessStatus === "pending" || refreshStatus === "pending"
      ? "pending"
      : accessStatus === "granted" && refreshStatus === "granted"
      ? "granted"
      : "denied";
  },
  getGDriveAccessToken(state) {
    const tokenIndex = state.tokens.findIndex(
      (token) => token.id === "gdrive_access_token"
    );

    return tokenIndex > -1 ? state.tokens[tokenIndex] : null;
  },
  getGCalAccessStatus(state) {
    const accessToken = state.tokens.find(
      (token) => token.id === "gcal_access_token"
    );
    const accessStatus =
      accessToken && accessToken.token && accessToken.token === "pending"
        ? "pending"
        : accessToken && accessToken.token && accessToken.token.length > 0
        ? "granted"
        : "denied";

    const refreshToken = state.tokens.find(
      (token) => token.id === "gcal_refresh_token"
    );
    const refreshStatus =
      refreshToken && refreshToken.token && refreshToken.token === "pending"
        ? "pending"
        : refreshToken && refreshToken.token && refreshToken.token.length > 0
        ? "granted"
        : "denied";

    return accessStatus === "pending" || refreshStatus === "pending"
      ? "pending"
      : accessStatus === "granted" && refreshStatus === "granted"
      ? "granted"
      : "denied";
  },
  getGCalAccessToken(state) {
    const tokenIndex = state.tokens.findIndex(
      (token) => token.id === "gcal_access_token"
    );

    return tokenIndex > -1 ? state.tokens[tokenIndex] : null;
  },
  getCalendarEventById: (state) => (id) => {
    const eventIndex = state.calendarEvents.items.findIndex(
      (event) => event.id === id
    );

    return eventIndex > -1 ? state.calendarEvents.items[eventIndex] : null;
  },
  getCalendarEvents(state) {
    return state.calendarEvents;
  },
};

const mutations = {
  // LISTENERS
  setListener(state, payload) {
    state.listeners.push(payload);
  },
  removeListener(state, index) {
    state.listeners.splice(index, 1);
  },
  removeAllListeners(state) {
    state.listeners = [];
  },
  // TOKEN MUTATIONS
  addToken(state, payload) {
    state.tokens.push(payload);
  },
  setToken(state, payload) {
    const tokenIndex = state.tokens.findIndex(
      (token) => token.id === payload.id
    );

    if (tokenIndex > -1) {
      // Remove then add, otherwise Array change isn't triggered on client
      state.tokens.splice(tokenIndex, 1, payload);
    }
  },
  removeToken(state, payload) {
    const tokenIndex = state.tokens.findIndex(
      (token) => token.id === payload.id
    );

    if (tokenIndex > -1) {
      state.tokens.splice(tokenIndex, 1);
    }
  },
  clearTokens(state) {
    state.tokens = [];
  },
  // GAPI RESULTS MUTATIONS
  setCalendarEvents(state, payload) {
    state.calendarEvents = payload;
  },
};

const actions = {
  // ================
  // Listener methods
  // ================
  stopAllGoogleApiListeners({ commit, state }) {
    for (let listener of state.listeners) {
      listener.listener();
    }
    commit("removeAllListeners");
  },

  setGoogleApiTokenListener({ commit, rootGetters }) {
    commit("clearTokens");

    const listener = db
      .collection("users/" + rootGetters["auth/getCurrentUser"].uid + "/auth")
      .onSnapshot(
        (tokensRef) => {
          tokensRef.docChanges().forEach((change) => {
            if (change.type === "added") {
              commit("addToken", { id: change.doc.id, ...change.doc.data() });
            }
            if (change.type === "modified") {
              commit("setToken", { id: change.doc.id, ...change.doc.data() });
            }
            if (change.type === "removed") {
              commit("removeToken", { id: change.doc.id });
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );

    // store listener so it can be closed (eg. when logged out)
    commit("setListener", { type: "token", listener: listener });
  },

  // =====================
  // Authorization methods
  // =====================
  /* eslint-disable */
  startGoogleDriveAuthorize(context) {
    // This returns a code which can be swapped for access and refresh tokens
    const googleCodeClient = google.accounts.oauth2.initCodeClient({
      client_id: clientId,
      scope: "https://www.googleapis.com/auth/drive.file",
      access_type: "offline",
      callback: (response) => {
        context.dispatch("getGoogleDriveTokens", response.code);
      },
      // state: "STATE-XYZ",
      ux_mode: "popup",
      error_callback: this.errorGoogleAuth,
    });

    googleCodeClient.requestCode();
  },
  startGoogleCalAuthorize(context) {
    // This returns a code which can be swapped for access and refresh tokens
    const googleCodeClient = google.accounts.oauth2.initCodeClient({
      client_id: clientId,
      scope: "https://www.googleapis.com/auth/calendar.readonly",
      access_type: "offline",
      callback: (response) => {
        context.dispatch("getGoogleCalTokens", response.code);
      },
      // state: "STATE-XYZ",
      ux_mode: "popup",
      error_callback: this.errorGoogleAuth,
    });

    googleCodeClient.requestCode();
  },
  /* eslint-enable */

  errorGoogleAuth(error) {
    console.log(error);
  },
  getGoogleDriveTokens({ dispatch, rootGetters }, authorizationCode) {
    const functions = getFunctions(firebaseApp);
    const getGDriveTokens = httpsCallable(functions, "getGDriveTokens");

    // Set Access Token as pending state
    if (
      rootGetters["auth/getCurrentUser"] &&
      rootGetters["auth/getCurrentUser"].uid
    ) {
      db.collection("users/" + rootGetters["auth/getCurrentUser"].uid + "/auth")
        .doc("gdrive_access_token")
        .set({
          token: "pending",
        });

      getGDriveTokens({ authCode: authorizationCode }).catch((error) => {
        console.log(error);
        dispatch("ui/showSnackbar", error.message, { root: true });
      });
    }
  },
  refreshGoogleDriveToken({ dispatch, rootGetters }) {
    return new Promise((resolve) => {
      const functions = getFunctions(firebaseApp);
      const refreshGDriveToken = httpsCallable(functions, "refreshGDriveToken");

      // Set Access Token as pending state
      if (
        rootGetters["auth/getCurrentUser"] &&
        rootGetters["auth/getCurrentUser"].uid
      ) {
        db.collection(
          "users/" + rootGetters["auth/getCurrentUser"].uid + "/auth"
        )
          .doc("gdrive_access_token")
          .set({
            token: "pending",
          });

        refreshGDriveToken()
          .then((refreshedToken) => {
            resolve(refreshedToken);
          })
          .catch((error) => {
            console.log(error);
            dispatch("ui/showSnackbar", error.message, { root: true });

            // Logout
            dispatch("auth/signOutFromGoogle", null, { root: true });
            resolve(error);
          });
      }
    });
  },
  getGoogleCalTokens({ dispatch, rootGetters }, authorizationCode) {
    const functions = getFunctions(firebaseApp);
    const getGCalTokens = httpsCallable(functions, "getGCalTokens");

    // Set Access Token as pending state
    if (
      rootGetters["auth/getCurrentUser"] &&
      rootGetters["auth/getCurrentUser"].uid
    ) {
      db.collection("users/" + rootGetters["auth/getCurrentUser"].uid + "/auth")
        .doc("gcal_access_token")
        .set({
          token: "pending",
        });

      getGCalTokens({ authCode: authorizationCode }).catch((error) => {
        console.log(error);
        dispatch("ui/showSnackbar", error.message, { root: true });
      });
    }
  },
  refreshGoogleCalToken({ dispatch, rootGetters }) {
    return new Promise((resolve) => {
      const functions = getFunctions(firebaseApp);
      const refreshGCalToken = httpsCallable(functions, "refreshGCalToken");

      // Set Access Token as pending state
      if (
        rootGetters["auth/getCurrentUser"] &&
        rootGetters["auth/getCurrentUser"].uid
      ) {
        db.collection(
          "users/" + rootGetters["auth/getCurrentUser"].uid + "/auth"
        )
          .doc("gcal_access_token")
          .set({
            token: "pending",
          });

        refreshGCalToken()
          .then((refreshedToken) => {
            resolve(refreshedToken);
          })
          .catch((error) => {
            console.log(error);
            dispatch("ui/showSnackbar", error.message, { root: true });

            // Logout
            dispatch("auth/signOutFromGoogle", null, { root: true });
            resolve(error);
          });
      }
    });
  },

  // ==============
  // Facade methods
  // ==============
  listFiles({ dispatch }, { searchQ }) {
    return new Promise((resolve, reject) => {
      dispatch("executeGoogleApiMethod", {
        apiMethod: "listDriveFiles",
        tokenRefreshMethod: "refreshGoogleDriveToken",
        params: {
          searchQ: searchQ,
        },
      })
        .then((retVal) => {
          resolve(retVal);
        })
        .catch((error) => {
          console.log(error);
          dispatch("ui/showSnackbar", error.message, { root: true });
          reject(error);
        });
    });
  },
  readFile({ dispatch }, { fileId }) {
    return new Promise((resolve, reject) => {
      dispatch("executeGoogleApiMethod", {
        apiMethod: "readDriveFile",
        tokenRefreshMethod: "refreshGoogleDriveToken",
        params: {
          fileId: fileId,
        },
      })
        .then((retVal) => {
          resolve(retVal);
        })
        .catch((error) => {
          console.log(error);
          dispatch("ui/showSnackbar", error.message, { root: true });
          reject(error);
        });
    });
  },
  getFile({ dispatch }, { fileId }) {
    return new Promise((resolve, reject) => {
      dispatch("executeGoogleApiMethod", {
        apiMethod: "getDriveFile",
        tokenRefreshMethod: "refreshGoogleDriveToken",
        params: {
          fileId: fileId,
        },
      })
        .then((retVal) => {
          resolve(retVal);
        })
        .catch((error) => {
          console.log(error);
          dispatch("ui/showSnackbar", error.message, { root: true });
          reject(error);
        });
    });
  },
  createDoc({ dispatch }, { fileName, fileContent }) {
    return new Promise((resolve, reject) => {
      dispatch("executeGoogleApiMethod", {
        apiMethod: "createDriveDoc",
        tokenRefreshMethod: "refreshGoogleDriveToken",
        params: {
          fileName: fileName,
          fileContent: fileContent,
        },
      })
        .then((retVal) => {
          resolve(retVal);
        })
        .catch((error) => {
          console.log(error);
          dispatch("ui/showSnackbar", error.message, { root: true });
          reject(error);
        });
    });
  },
  createFile({ dispatch }, { file }) {
    return new Promise((resolve, reject) => {
      dispatch("executeGoogleApiMethod", {
        apiMethod: "createDriveFile",
        tokenRefreshMethod: "refreshGoogleDriveToken",
        params: {
          file: file,
        },
      })
        .then((retVal) => {
          resolve(retVal);
        })
        .catch((error) => {
          console.log(error);
          dispatch("ui/showSnackbar", error.message, { root: true });
          reject(error);
        });
    });
  },
  setFileProperty(
    { dispatch },
    { fileId, filePropertyKey, filePropertyValue }
  ) {
    return new Promise((resolve, reject) => {
      dispatch("executeGoogleApiMethod", {
        apiMethod: "setDriveFileProperty",
        tokenRefreshMethod: "refreshGoogleDriveToken",
        params: {
          fileId: fileId,
          filePropertyKey: filePropertyKey,
          filePropertyValue: filePropertyValue,
        },
      })
        .then((retVal) => {
          resolve(retVal);
        })
        .catch((error) => {
          console.log(error);
          dispatch("ui/showSnackbar", error.message, { root: true });
          reject(error);
        });
    });
  },
  renameFile({ dispatch }, { fileId, fileName }) {
    return new Promise((resolve, reject) => {
      dispatch("executeGoogleApiMethod", {
        apiMethod: "renameDriveFile",
        tokenRefreshMethod: "refreshGoogleDriveToken",
        params: {
          fileId: fileId,
          fileName: fileName,
        },
      })
        .then((retVal) => {
          resolve(retVal);
        })
        .catch((error) => {
          console.log(error);
          dispatch("ui/showSnackbar", error.message, { root: true });
          reject(error);
        });
    });
  },
  updateFile({ dispatch }, { fileId, fileName, fileContent }) {
    return new Promise((resolve, reject) => {
      dispatch("executeGoogleApiMethod", {
        apiMethod: "updateeDriveFile",
        tokenRefreshMethod: "refreshGoogleDriveToken",
        params: {
          fileId: fileId,
          fileName: fileName,
          fileContent: fileContent,
        },
      })
        .then((retVal) => {
          resolve(retVal);
        })
        .catch((error) => {
          console.log(error);
          dispatch("ui/showSnackbar", error.message, { root: true });
          reject(error);
        });
    });
  },
  deleteFile({ dispatch }, { fileId }) {
    return new Promise((resolve, reject) => {
      dispatch("executeGoogleApiMethod", {
        apiMethod: "deleteDriveFile",
        tokenRefreshMethod: "refreshGoogleDriveToken",
        params: {
          fileId: fileId,
        },
      })
        .then((retVal) => {
          resolve(retVal);
        })
        .catch((error) => {
          console.log(error);
          dispatch("ui/showSnackbar", error.message, { root: true });
          reject(error);
        });
    });
  },
  createAppFolder({ dispatch }, newFolderName) {
    return new Promise((resolve, reject) => {
      dispatch("executeGoogleApiMethod", {
        apiMethod: "createDriveAppFolder",
        tokenRefreshMethod: "refreshGoogleDriveToken",
        params: {
          newFolderName: newFolderName,
        },
      })
        .then((retVal) => {
          resolve(retVal);
        })
        .catch((error) => {
          console.log(error);
          dispatch("ui/showSnackbar", error.message, { root: true });
          reject(error);
        });
    });
  },
  listCalendar({ dispatch }) {
    return new Promise((resolve) => {
      dispatch("executeGoogleApiMethod", {
        apiMethod: "listCalendarEvents",
        tokenRefreshMethod: "refreshGoogleCalToken",
      })
        .then((retVal) => {
          resolve(retVal);
        })
        .catch((error) => {
          console.log(error);
          dispatch("ui/showSnackbar", error.message, { root: true });

          // Logout
          dispatch("auth/signOutFromGoogle", null, { root: true });
          resolve(error);
        });
    });
  },
  listFileComments({ dispatch }, { fileId }) {
    return new Promise((resolve, reject) => {
      dispatch("executeGoogleApiMethod", {
        apiMethod: "listDriveFileComments",
        tokenRefreshMethod: "refreshGoogleDriveToken",
        params: {
          fileId: fileId,
        },
      })
        .then((retVal) => {
          resolve(retVal);
        })
        .catch((error) => {
          console.log(error);
          dispatch("ui/showSnackbar", error.message, { root: true });
          reject(error);
        });
    });
  },

  // ============
  // GAPI methods
  // ============
  // Execute a Google API method and handles access token refrsh and retry if need be
  executeGoogleApiMethod(
    { dispatch },
    { apiMethod, tokenRefreshMethod, params }
  ) {
    return new Promise((resolve, reject) => {
      dispatch(apiMethod, { params: params })
        .then((retVal) => {
          resolve(retVal);
        })
        .catch((error) => {
          // If access token expired, but the refresh of the token worked, re-request
          if (error && error.code === 401) {
            dispatch(tokenRefreshMethod).then((newToken) => {
              dispatch(apiMethod, {
                accessTokenParam: newToken ? newToken.data : null,
                params: params,
              })
                .then((retVal) => {
                  resolve(retVal);
                })
                .catch((error) => {
                  console.log(error);
                  dispatch("ui/showSnackbar", error.message, { root: true });
                  reject(error);
                });
            });
          }
        });
    });
  },
  listDriveFiles({ dispatch, rootGetters }, allParams) {
    return new Promise((resolve, reject) => {
      // accessToken to optional (in case we're running again after refreshing the token)
      const accessToken =
        allParams && allParams.accessTokenParam
          ? allParams.accessTokenParam
          : rootGetters["googleapi/getGDriveAccessToken"]
          ? rootGetters["googleapi/getGDriveAccessToken"].token
          : null;

      if (accessToken && allParams.params) {
        const qStr = allParams.params.searchQ
          ? "&q=" + allParams.params.searchQ
          : "";

        fetch(
          "https://www.googleapis.com/drive/v3/files" +
            "?fields=files(id, mimeType, name, webViewLink, iconLink, createdTime, modifiedTime, appProperties, parents)" +
            "&pageSize=10" +
            "&orderBy=modifiedTime desc" +
            qStr,
          {
            method: "GET",
            headers: new Headers({
              Authorization: "Bearer " + accessToken,
            }),
          }
        )
          .then((res) => res.json())
          .then((info) => {
            if (info && info.error) {
              if (info.error.code != 401) {
                // Don't display message for expired token, as we'll retry for them
                dispatch("ui/showSnackbar", info.error.message, { root: true });
              }
              reject(info.error);
            } else {
              resolve(info);
            }
          });
      }
    });
  },
  readDriveFile({ dispatch, rootGetters }, allParams) {
    return new Promise((resolve, reject) => {
      // accessToken to optional (in case we're running again after refreshing the token)
      const accessToken =
        allParams && allParams.accessTokenParam
          ? allParams.accessTokenParam
          : rootGetters["googleapi/getGDriveAccessToken"]
          ? rootGetters["googleapi/getGDriveAccessToken"].token
          : null;

      if (accessToken && allParams.params) {
        fetch(
          "https://www.googleapis.com/drive/v3/files/" +
            allParams.params.fileId +
            "?alt=media",
          {
            method: "GET",
            headers: new Headers({
              Authorization: "Bearer " + accessToken,
            }),
          }
        )
          .then((res) => res.text())
          .then((info) => {
            var errorObj;

            try {
              // If it can't be Json parsed, then it's valid data
              errorObj = JSON.parse(info);
            } catch (error) {
              resolve(info);
            }

            // If it contains error data, reject promise
            if (errorObj && errorObj.error) {
              if (errorObj.error.code != 401) {
                // Don't display message for expired token, as we'll retry for them
                dispatch("ui/showSnackbar", errorObj.error.message, {
                  root: true,
                });
              }
              reject(errorObj.error);
            } else {
              // Otherwise, file contains a valid JSON object - eg. Editor
              resolve(info);
            }
          });
      }
    });
  },
  getDriveFile({ dispatch, rootGetters }, allParams) {
    return new Promise((resolve, reject) => {
      // accessToken to optional (in case we're running again after refreshing the token)
      const accessToken =
        allParams && allParams.accessTokenParam
          ? allParams.accessTokenParam
          : rootGetters["googleapi/getGDriveAccessToken"]
          ? rootGetters["googleapi/getGDriveAccessToken"].token
          : null;

      if (accessToken && allParams.params) {
        fetch(
          "https://www.googleapis.com/drive/v3/files/" +
            allParams.params.fileId +
            "?fields=id, mimeType, name, webViewLink, iconLink",
          {
            method: "GET",
            headers: new Headers({
              Authorization: "Bearer " + accessToken,
            }),
          }
        )
          .then((res) => res.json())
          .then((info) => {
            var errorObj;

            // try {
            //   // If it can't be Json parsed, then it's valid data
            //   errorObj = JSON.parse(info);
            // } catch (error) {
            //   resolve(info);
            // }

            // If it contains error data, reject promise
            if (errorObj && errorObj.error) {
              if (errorObj.error.code != 401) {
                // Don't display message for expired token, as we'll retry for them
                dispatch("ui/showSnackbar", errorObj.error.message, {
                  root: true,
                });
              }
              reject(errorObj.error);
            } else {
              // Otherwise, file contains a valid JSON object - eg. Editor
              resolve(info);
            }
          });
      }
    });
  },
  createDriveDoc({ dispatch, rootGetters }, allParams) {
    return new Promise((resolve, reject) => {
      // accessToken to optional (in case we're running again after refreshing the token)
      const accessToken =
        allParams && allParams.accessTokenParam
          ? allParams.accessTokenParam
          : rootGetters["googleapi/getGDriveAccessToken"]
          ? rootGetters["googleapi/getGDriveAccessToken"].token
          : null;

      if (accessToken && allParams.params) {
        const boundary = "foo_bar_baz";
        const delimiter = "\r\n--" + boundary + "\r\n";
        const close_delim = "\r\n--" + boundary + "--";
        const fileName = allParams.params.fileName;
        const fileData = allParams.params.fileContent;
        const contentType = "application/vnd.google-apps.document";
        const metadata = {
          name: fileName,
          parents: [rootGetters["settings/getDriveAppFolderId"]],
          mimeType: contentType,
        };

        const multipartRequestBody =
          delimiter +
          "Content-Type: application/json; charset=UTF-8\r\n\r\n" +
          JSON.stringify(metadata) +
          delimiter +
          "Content-Type: text/plain; charset=UTF-8\r\n\r\n" +
          "\r\n\r\n" +
          fileData +
          "\r\n" +
          close_delim;

        fetch(
          "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
          {
            method: "POST",
            headers: new Headers({
              Authorization: "Bearer " + accessToken,
              "Content-Type": "multipart/related; boundary=" + boundary + "",
            }),

            body: multipartRequestBody,
          }
        )
          .then((res) => res.json())
          .then((info) => {
            if (info && info.error) {
              if (info.error.code != 401) {
                // Don't display message for expired token, as we'll retry for them
                dispatch("ui/showSnackbar", info.error.message, { root: true });
              }
              reject(info.error);
            } else {
              resolve(info);
            }
          });
      }
    });
  },
  createDriveFile({ dispatch, rootGetters }, allParams) {
    return new Promise((resolve, reject) => {
      // accessToken to optional (in case we're running again after refreshing the token)
      const accessToken =
        allParams && allParams.accessTokenParam
          ? allParams.accessTokenParam
          : rootGetters["googleapi/getGDriveAccessToken"]
          ? rootGetters["googleapi/getGDriveAccessToken"].token
          : null;

      let formData = new FormData();
      formData.append("file", allParams.params.file);
      formData.append(
        "metadata",
        new Blob(
          [
            JSON.stringify({
              name: allParams.params.file.name,
              parents: [rootGetters["settings/getDriveAppFolderId"]],
            }),
          ],
          { type: "application/json" }
        )
      );

      fetch("https://www.googleapis.com/upload/drive/v3/files", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + accessToken,
          Accept: "application/json",
          // "Content-Type": "multipart/form-data",
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((info) => {
          if (info && info.error) {
            if (info.error.code != 401) {
              // Don't display message for expired token, as we'll retry for them
              dispatch("ui/showSnackbar", info.error.message, { root: true });
            }
            reject(info.error);
          } else {
            resolve(info);
          }
        });
    });
  },
  setDriveFileProperty({ dispatch, rootGetters }, allParams) {
    // Adds a property to a file, or updates it if it already exists
    // NB: This currently will replace all properties, not merge new with existing

    return new Promise((resolve, reject) => {
      // accessToken to optional (in case we're running again after refreshing the token)
      const accessToken =
        allParams && allParams.accessTokenParam
          ? allParams.accessTokenParam
          : rootGetters["googleapi/getGDriveAccessToken"]
          ? rootGetters["googleapi/getGDriveAccessToken"].token
          : null;

      if (accessToken && allParams.params) {
        const requestBody =
          "{'key': '" +
          allParams.params.filePropertyKey +
          "', 'value': '" +
          allParams.params.filePropertyValue +
          "'}";

        fetch(
          "https://www.googleapis.com/drive/v2/files/" +
            allParams.params.fileId +
            "/properties",
          {
            method: "POST",
            headers: new Headers({
              Authorization: "Bearer " + accessToken,
            }),

            body: requestBody,
          }
        )
          .then((res) => res.json())
          .then((info) => {
            if (info && info.error) {
              if (info.error.code != 401) {
                // Don't display message for expired token, as we'll retry for them
                dispatch("ui/showSnackbar", info.error.message, { root: true });
              }
              reject(info.error);
            } else {
              resolve(info);
            }
          });
      }
    });
  },
  renameDriveFile({ dispatch, rootGetters }, allParams) {
    return new Promise((resolve, reject) => {
      // accessToken to optional (in case we're running again after refreshing the token)
      const accessToken =
        allParams && allParams.accessTokenParam
          ? allParams.accessTokenParam
          : rootGetters["googleapi/getGDriveAccessToken"]
          ? rootGetters["googleapi/getGDriveAccessToken"].token
          : null;

      if (accessToken && allParams.params) {
        const fileId = allParams.params.fileId;

        fetch("https://www.googleapis.com/drive/v3/files/" + fileId, {
          method: "PATCH",
          headers: new Headers({
            Authorization: "Bearer " + accessToken,
          }),

          body: "{name: '" + allParams.params.fileName + "'}",
        })
          .then((res) => res.json())
          .then((info) => {
            if (info && info.error) {
              if (info.error.code != 401) {
                // Don't display message for expired token, as we'll retry for them
                dispatch("ui/showSnackbar", info.error.message, { root: true });
              }
              reject(info.error);
            } else {
              resolve(info);
            }
          });
      }
    });
  },
  updateeDriveFile({ dispatch, rootGetters }, allParams) {
    return new Promise((resolve, reject) => {
      // accessToken to optional (in case we're running again after refreshing the token)
      const accessToken =
        allParams && allParams.accessTokenParam
          ? allParams.accessTokenParam
          : rootGetters["googleapi/getGDriveAccessToken"]
          ? rootGetters["googleapi/getGDriveAccessToken"].token
          : null;

      if (accessToken && allParams.params) {
        const boundary = "foo_bar_baz";
        const delimiter = "\r\n--" + boundary + "\r\n";
        const close_delim = "\r\n--" + boundary + "--";
        const fileId = allParams.params.fileId;
        const fileName = allParams.params.fileName;
        const fileData = allParams.params.fileContent;
        const contentType = "text/plain";
        const metadata = {
          name: fileName,
          mimeType: contentType,
        };

        const multipartRequestBody =
          delimiter +
          "Content-Type: application/json; charset=UTF-8\r\n\r\n" +
          JSON.stringify(metadata) +
          delimiter +
          "Content-Type: " +
          contentType +
          "\r\n\r\n" +
          fileData +
          "\r\n" +
          close_delim;

        fetch(
          "https://www.googleapis.com/upload/drive/v3/files/" +
            fileId +
            "?uploadType=multipart",
          {
            method: "PATCH",
            headers: new Headers({
              Authorization: "Bearer " + accessToken,
              "Content-Type": "multipart/related; boundary=" + boundary + "",
            }),

            body: multipartRequestBody,
          }
        )
          .then((res) => res.json())
          .then((info) => {
            if (info && info.error) {
              if (info.error.code != 401) {
                // Don't display message for expired token, as we'll retry for them
                dispatch("ui/showSnackbar", info.error.message, { root: true });
              }
              reject(info.error);
            } else {
              resolve(info);
            }
          });
      }
    });
  },
  deleteDriveFile({ dispatch, rootGetters }, allParams) {
    return new Promise((resolve, reject) => {
      // accessToken to optional (in case we're running again after refreshing the token)
      const accessToken =
        allParams && allParams.accessTokenParam
          ? allParams.accessTokenParam
          : rootGetters["googleapi/getGDriveAccessToken"]
          ? rootGetters["googleapi/getGDriveAccessToken"].token
          : null;

      if (accessToken && allParams.params) {
        fetch(
          "https://www.googleapis.com/drive/v3/files/" +
            allParams.params.fileId,
          {
            method: "DELETE",
            headers: new Headers({
              Authorization: "Bearer " + accessToken,
            }),
          }
        )
          .then((res) => res.text())
          .then((info) => {
            var errorObj;

            try {
              // If it can be Json parsed, then assume it's an error object
              errorObj = JSON.parse(info);
            } catch (error) {
              resolve(info);
            }

            if (errorObj && errorObj.error) {
              if (errorObj.error.code != 401) {
                // Don't display message for expired token, as we'll retry for them
                dispatch("ui/showSnackbar", errorObj.error.message, {
                  root: true,
                });
              }
              reject(errorObj.error);
            }
          });
      }
    });
  },
  createDriveAppFolder({ dispatch, rootGetters }, allParams) {
    return new Promise((resolve, reject) => {
      // accessToken to optional (in case we're running again after refreshing the token)
      const accessToken =
        allParams && allParams.accessTokenParam
          ? allParams.accessTokenParam
          : rootGetters["googleapi/getGDriveAccessToken"]
          ? rootGetters["googleapi/getGDriveAccessToken"].token
          : null;

      if (accessToken) {
        // Create application folder in GDrive
        fetch("https://www.googleapis.com/drive/v3/files", {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + accessToken,
          }),
          body: JSON.stringify({
            name:
              allParams && allParams.params && allParams.params.newFolderName
                ? allParams.params.newFolderName
                : "wotMatters",
            mimeType: "application/vnd.google-apps.folder",
          }),
        })
          .then((res) => res.json())
          .then((info) => {
            if (info && info.error) {
              if (info.error.code != 401) {
                // Don't display message for expired token, as we'll retry for them
                dispatch("ui/showSnackbar", info.error.message, { root: true });
              }
              reject(info.error);
            } else {
              dispatch(
                "settings/updateDriveAppFolder",
                { folderId: info.id, folderName: info.name },
                { root: true }
              );
              resolve(info);
            }
          });
      }
    });
  },
  listCalendarEvents({ dispatch, commit, rootGetters }, allParams) {
    return new Promise((resolve, reject) => {
      // accessToken to optional (in case we're running again after refreshing the token)
      const accessToken =
        allParams && allParams.accessTokenParam
          ? allParams.accessTokenParam
          : rootGetters["googleapi/getGCalAccessToken"]
          ? rootGetters["googleapi/getGCalAccessToken"].token
          : null;

      if (accessToken) {
        fetch(
          "https://www.googleapis.com/calendar/v3/calendars/primary/events" +
            "?maxResults=10" +
            "&calendarId=primary" +
            "&timeMin=" +
            moment().startOf("day").toISOString() +
            "&timeMax=" +
            moment().endOf("day").toISOString() +
            "&showDeleted=false" +
            "&singleEvents=true" +
            "&orderBy=startTime",
          {
            method: "GET",
            headers: new Headers({
              Authorization: "Bearer " + accessToken,
            }),
          }
        )
          .then((res) => {
            res
              .json()
              .then((info) => {
                if (info && info.error) {
                  if (info.error.code != 401) {
                    // Don't display message for expired token, as we'll retry for them
                    dispatch("ui/showSnackbar", info.error.message, {
                      root: true,
                    });
                  }
                  reject(info.error);
                } else {
                  commit("setCalendarEvents", info);
                  resolve(info);
                }
              })
              .catch((err) => {
                console.log(err);
                reject(err);
              });
          })
          .catch((err) => {
            // Logout
            dispatch("auth/signOutFromGoogle", null, { root: true });
            reject(err);
          });
      }
    });
  },
  listDriveFileComments({ dispatch, rootGetters }, allParams) {
    return new Promise((resolve, reject) => {
      // accessToken to optional (in case we're running again after refreshing the token)
      const accessToken =
        allParams && allParams.accessTokenParam
          ? allParams.accessTokenParam
          : rootGetters["googleapi/getGDriveAccessToken"]
          ? rootGetters["googleapi/getGDriveAccessToken"].token
          : null;

      if (accessToken && allParams.params) {
        fetch(
          "https://www.googleapis.com/drive/v3/files/" +
            allParams.params.fileId +
            "/comments" +
            "?includeDeleted=false&fields=comments",
          {
            method: "GET",
            headers: new Headers({
              Authorization: "Bearer " + accessToken,
            }),
          }
        )
          .then((res) => res.json())
          .then((info) => {
            if (info && info.error) {
              if (info.error.code != 401) {
                // Don't display message for expired token, as we'll retry for them
                dispatch("ui/showSnackbar", info.error.message, { root: true });
              }
              reject(info.error);
            } else {
              resolve(info);
            }
          });
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
