import { createApp } from "vue";
import App from "./App.vue";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import store from "./store/index";
import router from "./router";
import colors from "vuetify/lib/util/colors";
import { getAuth } from "firebase/auth";

const darkTheme = {
  dark: true,
  colors: {
    primary: "#0277bd",
    secondary: "#4caf50",
    tertiary: colors.grey.lighten2,
    quaternary: colors.grey.darken3,
    accent: "#fdd835",
    error: "#C62F2F",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107",
    important: "#C18C01",
    // surface: "#000000",
    // surface: "#111111",
    // surface: "#284D87",
    // surface: "#0D625C",
    // surface: "#15181B",
    // surface: "#1E1E1E",
    surface: "#232323",
    // surface: "#3C4042",
    // background: "#1E1E1E",
    // background: "#202124",
    background: "#000000",
    background2: "#1f3451",
    background3: "#131313",
    toolbar: "#20263A",
  },
};
const lightTheme = {
  dark: false,
  colors: {
    primary: "#0277bd",
    secondary: "#009688",
    tertiary: colors.grey.darken4,
    quaternary: colors.grey.darken3,
    accent: "#ffc107",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107",
    important: "#C18C01",
    surface: "#EDEFF0",
    background: "#FFFFFF",
    background2: "#F6F6F6",
    background3: "#F0F0F0",
    toolbar: "#6595ED",
  },
};

const vuetify = createVuetify({
  components,
  directives,
  theme: { defaultTheme: "darkTheme", themes: { darkTheme, lightTheme } },
});

const app = createApp(App);
app.use(vuetify);
app.use(store);
app.use(router);

app.mount("#app");

app.config.globalProperties.window = window

getAuth().onAuthStateChanged((user) => {
  store.dispatch("auth/updateCurrentUser", { user });

  // Redirect user to app if logged in and not already there
  if (user && router.currentRoute._value.name === "login") {
    router.push("/main");
  }
});
