<template>
  <v-app>
    <div id="app">
      <v-main>
        <div v-if="!isSignedIn || nonAppPageDisplayed">
          <router-view />
        </div>

        <div v-else style="display: flex">
          <wmNavDrawer
            v-if="isSignedIn"
            @updateDrawerOpen="updateDrawerOpen"
            style="height: 100vh; flex-shrink: 0"
          >
          </wmNavDrawer>

          <div style="display: flex; flex-wrap: nowrap; overflow-x: auto; height: 100vh">
            <router-view />
          </div>
        </div>

        <v-snackbar
          location="top"
          color="warning darken-2"
          v-model="snackbarVisible"
        >
          {{ getSnackbarText }}
        </v-snackbar>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import wmNavDrawer from "./components/wmNavDrawer.vue";
import router from "./router";

export default {
  components: {
    wmNavDrawer,
  },
  computed: {
    ...mapGetters("auth", ["isSignedIn"]),
    ...mapGetters("ui", ["getSnackbarDisplayed", "getSnackbarText"]),

    nonAppPageDisplayed() {
      return (
        router.currentRoute._value.name === "privacy" ||
        router.currentRoute._value.name === "tos"
      );
    },
    snackbarVisible: {
      get() {
        return this.getSnackbarDisplayed;
      },
      set(value) {
        this.setSnackbarDisplayed(value);
      },
    },
  },
  watch: {
    isSignedIn: function (val) {
      if (val) {
        this.stopAllTaskListeners();

        this.setTaskGroupListener();
        this.setTaskListener();
        this.setGoogleApiTokenListener();
        this.setSettingsListener();
        this.startCurrentTimeInterval();
      } else {
        this.stopAllTaskListeners();
        this.stopAllGoogleApiListeners();
        this.stopAllSettingsListeners();
        this.stopCurrentTimeInterval();
      }
    },
  },
  methods: {
    ...mapActions("task", [
      "setTaskListener",
      "setTaskGroupListener",
      "stopAllTaskListeners",
    ]),
    ...mapActions("googleapi", [
      "setGoogleApiTokenListener",
      "stopAllGoogleApiListeners",
    ]),
    ...mapActions("settings", [
      "setSettingsListener",
      "stopAllSettingsListeners",
    ]),
    ...mapActions("ui", [
      "setSnackbarVisible",
      "startCurrentTimeInterval",
      "stopCurrentTimeInterval",
    ]),
    ...mapMutations("ui", ["setSnackbarDisplayed", "setDrawerOpen"]),

    updateDrawerOpen(newValue) {
      this.setDrawerOpen(newValue);
    },
  },
  beforeUnmount() {
    this.stopAllTaskListeners();
    this.stopAllGoogleApiListeners();
    this.stopAllSettingsListeners();
    this.stopCurrentTimeInterval();
  },
};
</script>

<style>
#app {
  background-color: var(--v-surface-base);
}
.ProseMirror {
  padding: 5px;
}
</style>