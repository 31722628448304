import {
  getAuth,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import { googleSignIn } from "./googleSignIn";
import { initialiseFirebase } from "../../modules/firestore";
import router from "../../router";

initialiseFirebase();

const auth = getAuth(); // For authenticating with Firebase
const provider = new GoogleAuthProvider(); // For authenticating with Google

const state = {
  currentUser: null,
};

const getters = {
  isSignedIn(state) {
    return state.currentUser != null;
  },
  getCurrentUser(state) {
    return state.currentUser;
  },
};

const mutations = {
  setCurrentUser(state, payload) {
    state.currentUser = payload;
  },
};

const actions = {
  updateCurrentUser(context, { user }) {
    context.commit("setCurrentUser", user);
  },

  signInWithGoogle() {
    // Execute correct form of Google SignIn for the environment
    googleSignIn(auth, provider);
  },

  signOutFromGoogle() {
    signOut(auth)
      .then(() => {
        router.push("/");
      })
      .catch((error) => {
        console.log("Signout error: " + error);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
