<template>
  <div style="background-color: #20263a">
    <v-layout>
      <v-img style="height: 100vh" :src="require('@/assets/manonmountain.jpg')">
      </v-img>

      <div
        style="position: absolute; top: 100px; width: 100vw"
        v-if="showLoginOptions"
      >
        <v-sheet
          class="text-center mx-auto px-8 py-8"
          elevation="4"
          rounded
          max-width="400"
          color="white"
        >
          <div>
            <a href="https://wotmatters.com">
              <v-avatar class="mr-3" size="48">
                <v-img
                  :src="require('@/assets/wmLogo-96x96.png')"
                  width="70"
                ></v-img>
              </v-avatar>
            </a>
          </div>

          <div class="text-h5 font-weight-medium">wotMatters</div>

          <div class="mt-6">
            <p class="text-body mb-4">Welcome to wotMatters.</p>
          </div>

          <div class="mt-6">
            <p class="text-body-2 mb-4">
              If you have a Google account, please click this button to sign In.
            </p>
            <wmAuthGoogle class="mr-6" :class="xsOnly ? 'signin-xs' : ''" />
          </div>

          <div class="my-12">
            <p class="text-body-2 mb-4">
              Otherwise, please click
              <a href="https://www.google.com/account/about/">here</a> and
              follow Google's instructions to create an account. Once done,
              please return to wotMatters to sign in.
            </p>
          </div>

          <v-divider></v-divider>

          <div class="mt-6">
            <span class="text-body-2 mb-4">
              <a href="https://wotmatters.com/privacy.html">privacy</a>
            </span>
            |
            <span class="text-body-2 mb-4">
              <a href="https://wotmatters.com/terms.html">terms of use</a>
            </span>
          </div>
        </v-sheet>
      </div>

      <div v-else 
        style="position: absolute; top: 250px; width: 100vw; text-align: center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-layout>
  </div>
</template>



<script>
import wmAuthGoogle from "../components/wmAuthGoogle";
import { useDisplay } from "vuetify";
import { getAuth, getRedirectResult } from "firebase/auth";

export default {
  setup() {
    // Destructure only the keys we want to use
    const { xs, xsOnly, smOnly, smAndDown, mdAndUp, lgAndUp, lgAndDown } =
      useDisplay();

    return { xs, xsOnly, smOnly, smAndDown, mdAndUp, lgAndUp, lgAndDown };
  },
  components: { wmAuthGoogle },
  data() {
    return {
      showLoginOptions: false,
      features: [
        {
          icon: "mdi-wifi-strength-alert-outline",
          title: "Offline Access",
          text: "Your data is available to you around the clock, and you can continue to make updates, even when you're not connected to the internet.",
        },
        {
          icon: "mdi-update",
          title: "Frequent Updates",
          text: "We use this tool internally to manage our own work, and regularly find ideas to improve and simplify the experience. Once they're tested, these automatically become available to all users.",
        },
        {
          icon: "mdi-shield-outline",
          title: "Data Security",
          text: "All of your information is kept in Google data centres, using industry-strength encryption, so you know it's secure.",
        },
      ],
      fadeInElements: [],
    };
  },
  methods: {
    // handleScroll(evt) {
    handleScroll() {
      for (var i = 0; i < this.fadeInElements.length; i++) {
        var elem = this.fadeInElements[i];
        if (this.isElemVisible(elem)) {
          elem.classList.add("fadeInRight");
          this.fadeInElements.splice(i, 1); // only allow it to run once
        }
      }
    },
    isElemVisible(el) {
      var rect = el.getBoundingClientRect();
      var elemTop = rect.top + 400; // 400 = buffer
      var elemBottom = rect.bottom;
      return elemTop < window.innerHeight && elemBottom >= 0;
    },
  },
  mounted() {
    const auth = getAuth();
    getRedirectResult(auth)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access Google APIs.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;

        // The signed-in user info.
        if (!result) {
          this.showLoginOptions = true;
        }
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // // The email of the user's account used.
        // const email = error.customData.email;
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(error.message);
      });

    this.fadeInElements = Array.from(
      document.getElementsByClassName("fade-in-right")
    );
    document.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  beforeUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
</style>
